<script>
import rolesService from '@/services/roles.service'

export default {
  name: 'Roles',
	data() {
		return {
      headerFields: [
        "__slot:checkboxes",
        {
          name: "id",
          label: "ID",
        },
        {
          name: "name",
          label: this.$t('title'),
        },
        {
          name: "position",
          label: this.$t('position'),
        },
        "__slot:actions:edit",
        "__slot:actions:delete",
      ],
      items: []
		}
  },


  mounted() {
    this._get()
  },

	methods: {
    _get() {
      rolesService.getAll()
        .then(res => {
          this.items = res.data
        })
    },
    remove(id) {
      rolesService.remove(id)
        .then(success => {
          this.$notify({
            message: this.$t('m.itemSuccessfullyDeleted', { item: this.$tc('role') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          const index = this.items.findIndex(obj => obj.id === id)
          this.items.splice(index, 1)
          this.updatePosition(this.items)
        })
    },
    updatePosition(items) {
      this.items = items
      this.items.map((item, i) => {
        item.position = i + 1
        let modelItem = { ...item }
        this.edit({ data: modelItem })
        return item
      })
    },
    edit({ data }) {
      rolesService.edit({ item: data })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('role') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
	},
}
</script>

<template>
  <BaseCard>
    <div
      slot="header"
      class="text-right"
    >
      <router-link :to="{ name: 'singleRole', params: { id: 'new' } }">
        <BaseButton
          type="green"
          gradient
        >
          {{ $t('addNewItem', { item: $tc('role') }) }}
        </BaseButton>
      </router-link>
    </div>
    <div>
      <BaseDraggableList
        :items="items"
        model="roles"
        :supports-photo="false"
        @updatePosition="updatePosition"
        @remove="remove"
      />
    </div>
  </BaseCard>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
